import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import HomeHeader from '../../components/theme/HomeHeader'
import RenderBlock from '../../components/blocks/RenderBlock'
import DidYouKnow from '../../components/common/DidYouKnow'
import Offers from '../../components/common/Offers'
import { Provider as EntryProvider } from '../../contest/context/EntryContext';

const IndexPage = ({ data }) => (
  <Layout
    meta={data.startPage.meta}
    urls={data.startPage.urls}
    locale='fr'
  >
    <EntryProvider>
      <HomeHeader
        frontHeaderText={data.startPage.title_html}
        urls={data.startPage.urls}
        locale='fr'
      />
      <RenderBlock location="home" blocks={data.startPage.blocks} />
      <DidYouKnow blogPosts={data.prismic.allHome_solution_featureds.edges[0].node} locale="fr" />
      <Offers />
      <Footer
        extraRules={
          '<sup>†</sup>Les produits de papier Royale<sup>®</sup> sont fabriqués par Les Produits de consommation Irving Limitée et Les Produits de consommation Irving, Inc., des sociétés affiliées à J.D. Irving, Limited. J.D. Irving, Limited et ses sociétés affiliées ont planté plus de 1 milliard d’arbres depuis 1957.' +
          '<br/><br/>*Chaque année, les forêts sous la gestion de J.D. Irving, Limited retirent de l’atmosphère une quantité plus grande de carbone que celle émise durant le cycle de vie des produits de papier Royale<sup>®</sup>.'
        }
      /> 
    </EntryProvider>
  </Layout>
)

export const query = graphql`
query {
  startPage(guid: { eq: "54319571-6668-471c-bc49-71b26b4b0ddf" }, locale: { eq: "fr" }) {
    title_html
    slides {
      name
      link
      image_url
      mobile_image_url
    }
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...promotionBlockFragment
    }
    meta {
      description,
      keywords
      title
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
  }
  prismic {
    allHome_solution_featureds(lang: "fr-ca") {
      edges {
        node {
          blog_1 {
            ... on PRISMIC_Home_solution_article {
              title
              _meta {
                uid
                lang
              }
              preview_image
            }
          }
          blog_2 {
            ... on PRISMIC_Home_solution_article {
              title
              _meta {
                uid
                lang
              }
              preview_image
            }
          }
          blog_3 {
            ... on PRISMIC_Home_solution_article {
              title
              _meta {
                uid
                lang
              }
              preview_image
            }
          }
        }
      }
    }
  }
}
`

export default IndexPage
